import { Helmet } from 'react-helmet-async'

import { Box, Columns, MainContent } from '@campaignhub/suit-theme'

import AdContent from '@components/AdContent'
import PageHeader from '@components/PageHeader'

import useCampaign from '@hooks/useCampaign'

interface PageContentProps {
  campaignId: number,
  nestedNavigation?: boolean,
}

const PageContent = (props: PageContentProps) => {
  const { campaignId, nestedNavigation } = props

  const campaignPayload = useCampaign({ id: campaignId }, {
    performHttpRequests: true,
    requestOptions: {
      include: ['Artworks', 'Captions'],
    },
  })

  return (
    <>
      <Helmet>
        <title>Campaign Approval | AIM</title>
      </Helmet>

      <MainContent style={{ left: 0, width: '100%' }}>
        <PageHeader
          boxProps={{
            justifyContent: 'center',
            padding: 'xlarge',
            style: { left: nestedNavigation ?? 0 },
          }}
          title="Digital Marketing Approval"
        />

        <Box paddingX="large" paddingTop={[112, 105]}>
          <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
            <Columns.Main>

            </Columns.Main>

            <Columns.Sidebar>
              <AdContent campaignPayload={campaignPayload} />
            </Columns.Sidebar>
          </Columns>
        </Box>
      </MainContent>
    </>
  )
}

export default PageContent