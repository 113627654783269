import { useMemo } from 'react'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['campaignObjectives']

type UseCampaignObjectivesOptions = {
  filters?: {
    adType?: string,
  },
  performHttpRequests?: boolean,
}

function useCampaignObjectives(options: UseCampaignObjectivesOptions) {
  const {
    filters,
    performHttpRequests = false,
  } = options || {}

  const {
    adType: filterAdType,
  } = filters || {}

  const {
    updatedEntities: { campaignObjectives: campaignObjectivesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { loading: loadingCampaignObjectives } = useReduxAction(
    'campaignObjectives',
    'loadCampaignObjectives',
    {},
    [performHttpRequests],
    {
      shouldPerformFn: (entityReducer: ModuleState) => {
        const { errors, loaded, loading } = entityReducer
        return performHttpRequests && !loaded && !loading && !errors?.length
      },
    },
  )

  const entityCampaignObjectives = useSelector(reduxState => reduxState.entities.campaignObjectives)
 
  const filteredObjectivesByAdType = useMemo(() => {
    const filtered = Object.values(entityCampaignObjectives).filter((campaignObjective) => {
      const { category } = campaignObjective

      const matchAdType = filterAdType === undefined || filterAdType?.includes(category)

      return matchAdType
    })

    return sortArrayBy(filtered, 'asc', 'displayName')
  }, [campaignObjectivesUpdatedAt, JSON.stringify(filters)])

  const campaignObjectives = filteredObjectivesByAdType.map(item => ({
    key: item.name,
    label: item.displayName
  }))

  return {
    loading: loadingCampaignObjectives,
    campaignObjectives,
  }
}

export default useCampaignObjectives
