import {
  Box, DashboardModule,
} from '@campaignhub/suit-theme'

import { useFilters } from '@campaignhub/react-hooks'

import DashboardModuleFilters from '@components/DashboardModuleFilters'

import useClientPriceLists from '@hooks/useClientPriceLists'

import ClientPriceListListItem from './ClientPriceListListItem'

const buildPageFilterFields = () => {
  const style = { marginBottom: 16 }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'clientName',
      label: 'Filter by Name',
    },
  ]
}

interface ClientPriceListsProps {
  priceListId: number,
}

const ClientPriceLists = (props: ClientPriceListsProps) => {
  const { priceListId } = props

  const filterPayload = useFilters()

  const {
    pageFilters,
  } = filterPayload

  const options = {
    filters: {
      isClientRequired: true,
      priceListId,
      ...pageFilters,
    },
    performHttpRequests: !!priceListId,
    requestOptions: {
      include: 'client',
    },
    sortKey: 'client' as 'client',
  }

  const clientPriceListsPayload = useClientPriceLists(options)

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredClientPriceLists,
    filteredClientPriceListsCount,
    hasFilteredClientPriceLists,
    loading,
  } = clientPriceListsPayload

  return (
    <DashboardModule
      boxProps={{ borderTop: loading ? 'none' : '1px solid lineColor' }}
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={<DashboardModule.FilterHeader {...filterPayload} />}
      loading={loading}
      title="Organisations"
    >
      <DashboardModuleFilters
        filterFields={buildPageFilterFields()}
        {...filterPayload}
      />

      <Box flexDirection="column">
        <DashboardModule.ScrollableSection flexDirection="column">
          {!loading && !hasFilteredClientPriceLists && (
            <DashboardModule.BlankState>
              <DashboardModule.BlankState.Title>
                No Organisations present
              </DashboardModule.BlankState.Title>

              <DashboardModule.BlankState.Paragraph>
                Please adjust your filters or link an Organisation
              </DashboardModule.BlankState.Paragraph>
            </DashboardModule.BlankState>
          )}

          {!loading && filteredClientPriceLists.map(clientPriceList => (
            <ClientPriceListListItem
              clientPriceList={clientPriceList}
              key={clientPriceList.id}
            />
          ))}
        </DashboardModule.ScrollableSection>

        <DashboardModule.LoadMoreFooter
          boxProps={{ borderTop: loading ? 'none' : '1px solid' }}
          callbacks={{ loadMore }}
          canLoadMore={canLoadMore}
          entityCount={filteredClientPriceListsCount}
          loading={loading}
        />
      </Box>
    </DashboardModule>
  )
}

export default ClientPriceLists
