import { useMemo } from 'react'

import { useModals, useNumericParams, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import ManageCaptionsModal from '@modals/ManageCaptionsModal'

import PageContent from './PageContent'

const defaultState = {
  showManageCaptionsModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    ManageCaptionsModal: {
      closeModal: () => setState({ showManageCaptionsModal: false }),
      createOrUpdateCaptions: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

interface DigitalMarketingApprovalProps {
  nestedNavigation?: boolean,
}

const DigitalMarketingApproval = (props: DigitalMarketingApprovalProps) => {
  const { nestedNavigation } = props

  const [state, setState] = useSetState(defaultState)
  const { showManageCaptionsModal } = state

  const { campaignId } = useNumericParams()

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showManageCaptionsModal: (payload: { captionsParams: { campaignId: number } }) => {
        setModalData('ManageCaptionsModal', payload)
        setState({ showManageCaptionsModal: true })
      },
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent campaignId={campaignId} nestedNavigation={nestedNavigation} />

        <ManageCaptionsModal
          callbacks={callbacks('ManageCaptionsModal', setState)}
          showModal={showManageCaptionsModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default DigitalMarketingApproval
