import { useSetState } from '@campaignhub/react-hooks'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useAdContent from '@hooks/useAdContent'
import type { CaptionsFormState } from '@hooks/useAdContent'
import useCaption from '@hooks/useCaption'

import type { CaptionModel } from '@models/types'

import type { CreateOrUpdateParams } from '@redux/modules/caption'

type HandleAddCaptionParams = {
  callbacks: {
    createOrUpdateCaptions: (payload: HandleCallbackActionParams) => void,
    createOrUpdateCaptionsFn: (captionsParams: CreateOrUpdateParams) => Promise<
      { success: true, data: CaptionModel } | { success: false, errors: string[] }
    >
    setCaptionsFormState: React.Dispatch<React.SetStateAction<CaptionsFormState>>,
    setState: (state: Partial<typeof defaultState>) => void,
  },
  captionsFormState: CaptionsFormState,
  captionText: string,
  captionType: string,
}

const handleAddCaption = (params: HandleAddCaptionParams) => {
  const {
    callbacks: {
      createOrUpdateCaptions,
      createOrUpdateCaptionsFn,
      setCaptionsFormState,
      setState,
    },
    captionsFormState,
    captionText,
    captionType,
  } = params

  const updatedCaptionsFormState = {
    ...captionsFormState,
    [captionType]: [...captionsFormState[captionType], captionText],
  }

  setCaptionsFormState(updatedCaptionsFormState)
  setState({ captionText: '' })

  createOrUpdateCaptions({
    callbacks: {
      action: createOrUpdateCaptionsFn,
    },
    entityParams: updatedCaptionsFormState,
    toastText: 'Ad Content Updated',
  })
}

const captionMaxLengthsByType = {
  descriptions: 25,
  displayUrls: 240,
  headlines: 90,
  texts: 125,
}

const validateCaptionLength = (captionText: string, captionType: string) => {
  const maxLength = captionMaxLengthsByType[captionType]

  if (!maxLength) return { isValid: true, error: null }

  if (captionText.length > maxLength) {
    return { isValid: false, error: `Must be less than ${maxLength} chars` }
  }

  return { isValid: true, error: null }
}

const defaultState = {
  captionText: '',
  captionType: '',
}

type UseManageCaptionsModalParams = {
  campaignId: number,
}

const useManageCaptionsModal = (params: UseManageCaptionsModalParams) => {
  const { campaignId } = params

  const [state, setState] = useSetState(defaultState)
  const { captionText, captionType } = state 

  const {
    callbacks: {
      setCaptionsFormState,
    },
    captionsFormState,
  } = useAdContent(campaignId)

  const {
    callbacks: {
      createOrUpdateCaptions: createOrUpdateCaptionsFn,
    },
    creating,
  } = useCaption({ campaignId })

  return {
    callbacks: {
      createOrUpdateCaptionsFn,
      handleAddCaption: (
        createOrUpdateCaptions: (payload: HandleCallbackActionParams) => void,
      ) => handleAddCaption({
        callbacks: {
          createOrUpdateCaptions,
          createOrUpdateCaptionsFn,
          setCaptionsFormState,
          setState,
        },
        captionsFormState,
        captionText,
        captionType,
      }),
      setCaptionsFormState,
      setState,
      validateCaptionLength,
    },
    captionsFormState,
    creating,
    state,
  }
}

export default useManageCaptionsModal