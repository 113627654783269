import { formatNumber } from 'accounting'

import { SidebarModal } from '@campaignhub/suit-theme'

import type { AdCampaignModel } from '@models/types'

import DetailsListItem from '@components/DetailsListItem'

type ReportingProps = {
  adCampaign: AdCampaignModel,
}

const Reporting = (props: ReportingProps) => {
  const {
    adCampaign: {
      reporting,
    },
  } = props

  if (!reporting) return null

  const {
    clicks,
    clickThroughRate,
    costPerClick,
    impressions,
  } = reporting

  return (
    <SidebarModal.ExpandableSection
      defaultOpen
      label="Campaign Insights"
      style={{ marginTop: 16 }}
    >
      <DetailsListItem label="Impressions" text={impressions ? formatNumber(impressions) : 0} />

      <DetailsListItem label="Clicks" text={clicks ? formatNumber(clicks) : 0} />

      <DetailsListItem label="Click Through Rate" text={clickThroughRate ? `${formatNumber(clickThroughRate, 2)}%` : '0%'} />

      <DetailsListItem label="Average Cost" text={costPerClick ? `$${formatNumber(costPerClick, 2)}` : '$0'} />
    </SidebarModal.ExpandableSection>
  )
}

export default Reporting
