import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'

import { faTachometer } from '@fortawesome/pro-light-svg-icons'

import { Box, Columns } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import type { DefaultContext } from '@contexts/pageContext'

import useAdCampaignStatuses from '@hooks/useAdCampaignStatuses'
import useAdCampaigns from '@hooks/useAdCampaigns'
import useCampaignObjectives from '@hooks/useCampaignObjectives'
import type { AdCampaignFilters } from '@hooks/useAdCampaigns'

import type { AdCampaignStatusModel } from '@models/types'

import PageFilterModule from '@components/PageFilterModule'
import PageHeader from '@components/PageHeader'

import ActiveCampaigns from '../ActiveCampaigns'
import LoadedCampaigns from '../LoadedCampaigns'
import type { PageContextPayload } from '../..'


type BuildPageFilterFieldParams = {
  adCampaignStatuses: AdCampaignStatusModel[],
  campaignObjectives: { key: string, label: string }[],
}

const buildPageFilterFields = (params: BuildPageFilterFieldParams) => {
  const { adCampaignStatuses, campaignObjectives } = params

  const style = { marginBottom: 16 }
  const boxProps = { marginBottom: 'large' }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        placeholder: 'Search by AIM#',
        style,
      },
      key: 'campaignId',
      label: 'Search',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'status',
      label: 'Status',
      values: adCampaignStatuses.map(adCampaignStatus => (
        { key: adCampaignStatus.name, label: adCampaignStatus.name }
      ))
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'objective',
      label: 'Campaign Objective',
      values: campaignObjectives,
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'deploymentStack',
      label: 'Deployment Stack',
      values: [
        { key: 'v2', label: 'v2' },
        { key: 'v1', label: 'v1' },
      ],
    },
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        entityKey: 'clients',
        entityTitleKey: 'name',
      },
      key: 'clientId',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        inputType: 'date',
        style,
      },
      key: 'startDate',
      label: 'Start Date',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        inputType: 'date',
        style,
      },
      key: 'endDate',
      label: 'End Date',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'Latest First',
        inputComponent: 'select',
        style,
      },
      key: 'sortBy',
      label: 'Sort By',
      values: [
        { key: 'asc', label: 'Oldest First' },
      ],
    },
  ]
}

interface PageContentProps {
  pageFilters: AdCampaignFilters,
}

const PageContent = (props: PageContentProps) => {
  const {
    pageFilters,
  } = props

  const { adType } = pageFilters

  const { adCampaignStatuses } = useAdCampaignStatuses({ performHttpRequests: true })

  const { campaignObjectives } = useCampaignObjectives({
    filters: { adType },
    performHttpRequests: true,
  })

  const pageContext = useContext<DefaultContext<PageContextPayload>>(PageContext)
  const {
    callbacks: {
      resetFilters,
      updateFilters,
    },
    defaultFilters,
  } = pageContext

  useAdCampaigns({
    filters: {
      ...pageFilters,
    },
    performHttpRequests: true,
  })

  return (
    <>
      <Helmet>
        <title>Ad Loader | AIM Digital</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="campaigns"
        tabBarItems={[
          {
            href: '#/adLoader',
            icon: faTachometer,
            key: 'campaigns',
            title: 'All Campaigns',
          },
        ]}
        title="Ad Loader"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadedCampaigns pageFilters={pageFilters} />

            <ActiveCampaigns pageFilters={pageFilters} />
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields({ adCampaignStatuses, campaignObjectives })}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
