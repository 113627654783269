import { Route, Routes, HashRouter as Router } from 'react-router-dom'

import DigitalMarketingApproval from '@components/DigitalMarketingApproval'

import Campaign from './packs/Campaign'
import Client from '.'
import Dashboard from './packs/Dashboard'
import OrderItemTargeting from './packs/OrderItemTargeting'

const ClientRoutes = () => (
  <Router>
    <Client>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/campaigns/:campaignId/approval" element={<DigitalMarketingApproval />} />
        <Route path="/campaigns/:campaignId/*" element={<Campaign />} />
        <Route path="/orderItems/targeting" element={<OrderItemTargeting />} />
      </Routes>
    </Client>
  </Router>
)

export default ClientRoutes
